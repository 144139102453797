import React from "react"
import SectionTitle from "../../components/SectionTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PageWrapper from "../../components/layout/PageWrapper"
import ServicesChimney from "../../components/content/ServicesChimney"

const GasFireplaceInstallers = () => (
  <Layout>
    <SEO
      title="Flue liners, chimney survey and sweeping"
      description="Gas, Solid Fuel And Bio Fuel Fireplaces Design and Installation"
    />
    <PageWrapper>
      <SectionTitle
        title="Flue liners and chimney services"
        strapline="Flue liners, chimney survey, sweeping and repairs"
      />
      <ServicesChimney />
    </PageWrapper>
  </Layout>
)

export default GasFireplaceInstallers
