import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import ServicesImages from "./ServicesImages"

const query = graphql`
  {
    images: allFile(
      filter: { relativeDirectory: { eq: "pages/services/services-chimney" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 64, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const ServicesChimney = () => {
  const data = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <article className="text-container">
        <p>
          Fireplace and stove installation projects usually require additional
          services like complete chimney flue inspection. Our expert can provide
          a full inspection (including smoke test, draw test, pressure test)
          into your chimney.
        </p>
        <p>
          If you have an older property, the chimneys could be porous or leaky,
          a flue liner can be the ideal solution in that case. We can supply
          flue lining system so that your chimney is well-protected and provide
          better extraction.
        </p>
        <p>
          We can also provide a wide range of preventative solutions such as
          fitting a chimney cowl to avoid birds nesting, as well as stopping the
          obstacles from affecting the flue and fireplace itself.
        </p>
        <div className="back-to-button">
          <Link to={"/services"} className="btn btn--small">
            Back to services
          </Link>
        </div>
      </article>
      <aside className="image-container">
        <ServicesImages data={data} />
      </aside>
    </Wrapper>
  )
}

export default ServicesChimney

const Wrapper = styled.main`
  .image-container {
    margin-bottom: 2rem;
    height: 100%;
  }
  .text-container p {
    line-height: 2;
  }
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 4rem;

    .text-container {
      grid-column: 1 / span 6;
    }

    .image-container {
      grid-column: 7 / -1;
    }
  }
`
